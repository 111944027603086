<template>
  <div class="media-display-page" v-bind:class="{'media-display-page-intro': introActive, 'media-display-page-outro': outroActive}">
    
    <b-link class="btn btn-link text-white media-btn-back" to="/media"><img src="/img/icon-arrow-right.svg" alt="Back"><span>Media Room</span></b-link>

    <div class="media-followup" v-on:click="nextVideo(nextMediaItem.id)" v-if="nextMediaItem !== null">
      <img class="thumb" :src="'/img/media-library/small/' + nextMediaItem.imgUrl" :alt="nextMediaItem.title">
      <div class="content">
        <span>Next <img src="/img/icon-arrow-right-dark.svg" alt="Next"></span>
        <h5>{{nextMediaItem.title}}</h5> 
      </div>
    </div>

    <div class="bg-transition" :class="bgClass">
      <div class="quiz-wrapper">
        
        <div class="container media-player-container">
          <div class="row" v-if="mediaItem !== null && mediaItem.provider === 'soundcloud'">
            <div class="col-12">
              <div class="media-player-audio">
                <transition name="fade" mode="out-in" appear>
                  <div>
                    <iframe class="mb-0" width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" :src="mediaItem.soundcloud"></iframe>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <div class="row" v-if="mediaItem !== null && (mediaItem.provider === 'vimeo' || mediaItem.provider === 'youtube')">
            <div class="col-12">
              <div class="media-player-video">
                <transition name="fade" mode="out-in" appear>
                  <div v-show="mediaLoaded">
                    <VueVideoWrapper ref="videoPlayer" :player="mediaItem.provider" :videoId="mediaItem.source" @loaded="loadedVideo()" @ready="loadedVideo()" @ended="endedVideo()" :options="{byline: false}" />
                  </div>
                </transition>
                <figure v-on:click="playVideo()">
                  <img :src="'/img/media-library/large/' + mediaItem.imgUrl" :alt="mediaItem.title">
                </figure>
              </div>
            </div>
          </div>

        </div>
        
        <div class="container mt-4 media-item-info mb-4" v-if="mediaItem !== null">
          <div class="row">
            <div class="col-12 col-md-8 text-white">
              <h2 class="text-white">{{mediaItem.title}}</h2>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import mediaContent from '@/data/mediaLibrary.json'
import VueVideoWrapper from 'vue-video-wrapper' // https://www.npmjs.com/package/vue-video-wrapper
// import SoundCloud from 'vue-soundcloud-player' // https://www.npmjs.com/package/vue-soundcloud-player

export default {
  components: { VueVideoWrapper },
  name: 'MediaDisplay',
  props: ['id'],
  data() {
    return {
      bgClass: "",
      mediaContent: mediaContent,
      mediaItem: null,
      nextMediaItem: null,
      mediaLoaded: false,
      introActive: true,
      outroActive: false
    }
  },
  computed: {
  },
  methods: {
    hideNav: function () {
      this.$store.state.logoHide = true;
    },
    showNav: function () {
      this.$store.state.logoHide = false;
    },
    loadedVideo: function () {
      var self = this;
      self.mediaLoaded = true;
      self.introActive = false;
      self.outroActive = false;
      if (this.mediaItem.provider === 'vimeo') {
        this.$refs.videoPlayer.play()
      } else if (this.mediaItem.provider === 'youtube') {
        this.$refs.videoPlayer.player.playVideo()
      }
    },
    playVideo: function () {
      this.mediaLoaded = true;
      this.introActive = false;
      this.outroActive = false;
      if (this.mediaItem.provider === 'vimeo') {
        this.$refs.videoPlayer.play()
      } else if (this.mediaItem.provider === 'youtube') {
        this.$refs.videoPlayer.player.playVideo()
      }
    },
    endedVideo: function () {
      this.introActive = true;
      this.outroActive = true;
    },
    nextVideo: function (id) {
      console.log(id)
      this.$router.push({ name: 'MediaDisplay', params: { id }})
    }
  },
  mounted() {
    var self = this;
    this.$store.state.home = false;
    this.$store.state.logoHide = true;
    this.$store.state.navHide = false;
    this.$store.state.bodyBackground = "bg-dark";

    console.log(this.id)

    // Find item data in mediaLibrary.json
    this.mediaItem = this.mediaContent.find(el => el.id === this.id); 
    if (this.mediaItem === undefined) {
      this.$router.push('/media')
    } else {
      console.log(this.mediaItem);
    }

    if (this.mediaItem.provider === 'soundcloud') {
      this.mediaItem.soundcloud = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + this.mediaItem.source + "&color=%23a20000&auto_play=true&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=true&sharing=false&buying=false&show_playcount=false";

      setTimeout(function(){ 
        self.outroActive = true;
      }, 10000);
    }

    var index = this.mediaContent.findIndex(el => el.id === this.id);
    var nextIndex = index + 1;
    if (nextIndex === this.mediaContent.length) {
      nextIndex = 0;
    }
    this.nextMediaItem = this.mediaContent[nextIndex];
  }
}
</script>